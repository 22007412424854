<template>
  <v-card>
    <v-card-title>Заказчик</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col>
          <text-line title="Заказчик" :value="order.customerName"/>
        </v-col>
        <v-col>
          <text-line title="Телефон заказчика" :value="order.customerPhone"/>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <text-line title="Комментарии к заказу" :value="order.customerNotes"/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>

import TextLine from '@/components/ui/TextLine.vue'

export default {
  components: { TextLine },
  props: {
    order: {
      type: Object,
      required: true
    }
  }
}
</script>
